import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, ViewEncapsulation } from "@angular/core";
import { VariableI } from "../../pojo/VariableI";
import { LooseAutocomplete } from "../../pojo/Util";
import { BpeVarsConst } from "./s25.bpe.const";
import { Doc } from "../system-settings/document-management/s25.document.const";
import { BpeVarsUtil } from "./s25.bpe.vars.util";

@Component({
    selector: "s25-ng-table-builder-variable",
    template: `
        <s25-bpe-vars-dropdown
            class="c-margin-top--quarter"
            [(chosen)]="tableBuilder.type"
            [type]="type === 'email' ? 'tables' : 'documentTables'"
            (chosenChange)="onTableTypeSelect()"
            [scope]="scope"
        ></s25-bpe-vars-dropdown>
        @if (tableBuilder.type?.val === "occurrences") {
            <s25-ng-checkbox
                [(modelValue)]="tableBuilder.includeLocations"
                (modelValueChange)="updateTableKey(); updateTableVar()"
            >
                Include Locations
            </s25-ng-checkbox>
            <br />
            <s25-ng-checkbox
                [(modelValue)]="tableBuilder.includeResources"
                (modelValueChange)="updateTableKey(); updateTableVar()"
            >
                Include Resources
            </s25-ng-checkbox>
            <br />
            @if (scope !== "reservation" && (tableBuilder.includeLocations || tableBuilder.includeResources)) {
                <s25-ng-checkbox
                    [(modelValue)]="tableBuilder.isPref"
                    (modelValueChange)="updateTableKey(); updateTableVar()"
                >
                    Pref Variables
                </s25-ng-checkbox>
            }
        }
        @if (tableBuilder.type?.val && tableBuilder.type?.val !== "none") {
            <div>
                <s25-ng-table-builder
                    [(columns)]="tableBuilder.columns"
                    [variableType]="tableBuilder.variablesKey"
                    [dateFormatType]="type === 'email' ? 'tableDateFormats' : 'tableDocumentDateFormats'"
                    [hasDataColumn]="tableBuilder.type.hasData"
                    [hasWidthColumn]="type === 'document'"
                    [dataColumnName]="tableBuilder.type.dataColumnLabel"
                    (columnsChange)="updateTableVar()"
                >
                </s25-ng-table-builder>
                @if (tableBuilder.type.val && tableBuilder.templateVariable) {
                    <div>
                        @if (!tableBuilder.isPref) {
                            <s25-ng-copy-variable [label]="'Table Variable'" [text]="tableBuilder.templateVariable" />
                        }
                        @if (type !== "document" && !tableBuilder.isPref) {
                            <s25-ng-copy-variable [label]="'Pre Variable'" [text]="tableBuilder.templatePreVariable" />
                        }
                        @if (tableBuilder.hasPref && tableBuilder.isPref) {
                            <s25-ng-copy-variable
                                [label]="'Pref Variable'"
                                [text]="tableBuilder.templatePrefVariable"
                            />
                        }
                        @if (tableBuilder.hasPref && tableBuilder.isPref && type !== "document") {
                            <s25-ng-copy-variable
                                [label]="'PrePref Variable'"
                                [text]="tableBuilder.templatePrePrefVariable"
                            />
                        }
                    </div>
                }
            </div>
        }
    `,
    styles: ``,
    encapsulation: ViewEncapsulation.Emulated,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class S25TableBuilderVariableComponent {
    @Input({ required: true }) type: LooseAutocomplete<"email"> = "email";
    @Input({ required: true }) scope: Doc.ScopeId = "event";

    tableBuilder: {
        type?: VariableI;
        includeLocations?: boolean; // Only for occurrences
        includeResources?: boolean; // Only for occurrences
        isPref?: boolean; // Only for occurrences
        hasPref?: boolean;
        variablesKey?: keyof typeof BpeVarsConst.Items;
        templateVariable?: string;
        templatePreVariable?: string;
        templatePrefVariable?: string;
        templatePrePrefVariable?: string;
        columns: {
            header: string;
            math?: string;
            format?: VariableI;
            variable?: VariableI;
            width?: number;
        }[];
    } = { columns: [{ header: "New Column", width: 1 }] };

    constructor(private cd: ChangeDetectorRef) {}

    onTableTypeSelect() {
        const { type } = this.tableBuilder;
        const variablesKey = type.val as keyof typeof BpeVarsConst.Items;
        this.tableBuilder = {
            type,
            variablesKey,
            hasPref: ["locations", "resources"].includes(type.val),
            columns: this.getDefaultTableColumns(variablesKey),
        };
        this.updateTableVar();
        this.cd.detectChanges();
    }

    getDefaultTableColumns(variablesKey: keyof typeof BpeVarsConst.Items) {
        if (this.tableBuilder.type.val === "none") return [];
        return (BpeVarsConst.Items[variablesKey] as any[]).map((item) => ({
            header: item.txt,
            variable: item,
            width: item.width,
        }));
    }

    updateTableKey() {
        const { type, includeResources, includeLocations } = this.tableBuilder;
        let key = type.val as keyof typeof BpeVarsConst.Items;
        // includeLocations and includeResources are only present on occurrences
        this.tableBuilder.hasPref = key === "locations" || key === "resources" || includeResources || includeLocations;
        this.tableBuilder.isPref = this.tableBuilder.hasPref && this.tableBuilder.isPref;
        if (includeLocations) key += "Locations";
        if (includeResources) key += "Resources";
        if (this.tableBuilder.isPref && (includeLocations || includeResources)) key += "Pref";
        this.tableBuilder.variablesKey = key;
        this.tableBuilder.columns = this.getDefaultTableColumns(key);
        this.cd.detectChanges();
    }

    updateTableVar() {
        // {{for:$pro.vars.occurrences:rsrvState:State||dates.rsrvStartDate|mediumDate:Start||rsrvStateName:State:end-for}}
        const { type, includeResources, includeLocations } = this.tableBuilder;
        const isDocument = this.type === "document";
        const columns = BpeVarsUtil.getTableBuilderColumnsStr(this.tableBuilder.columns, isDocument);
        if (columns) {
            let variable = type.val;
            if (type.val === "occurrences" && includeLocations) variable += "Locations";
            if (type.val === "occurrences" && includeResources) variable += "Resources";
            const typeStr = variable.slice(0, 1).toUpperCase() + variable.slice(1);
            const tablePrefix = isDocument ? "ws.flexTable" : "$pro.vars";

            this.tableBuilder.templateVariable = `{{for: ${tablePrefix}.${variable}: ${columns} :end-for}}`;
            this.tableBuilder.templatePreVariable = `{{for: ${tablePrefix}.pre${typeStr}: ${columns} :end-for}}`;
            this.tableBuilder.templatePrefVariable = `{{for: ${tablePrefix}.pref${typeStr}: ${columns} :end-for}}`;
            this.tableBuilder.templatePrePrefVariable = `{{for: ${tablePrefix}.prePref${typeStr}: ${columns} :end-for}}`;
        } else this.tableBuilder.templateVariable = "";
        this.cd.detectChanges();
    }
}
