import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from "@angular/core";
import { S25Util } from "../../util/s25-util";

@Component({
    selector: "s25-ng-copy-variable",
    template: `
        @if (text) {
            <label class="copy">
                <span class="selectedVarLabel">{{ label }}: </span>
                <a
                    class="c-textButton tooltipButton"
                    title="Copy to Clipboard"
                    aria-label="copy event variable to clipboard"
                    (click)="copyTextToClipBoard(text)"
                    (keyup.enter)="copyTextToClipBoard(text)"
                    tabindex="0"
                >
                    {{ text }}
                    <span class="tooltipBefore">Copy to Clipboard</span>
                    <span class="tooltipAfter">Copied!</span>
                </a>
            </label>
        }
    `,
    styles: `
        .copy {
            margin-top: 0.5em;
            display: block;
        }
    `,
    standalone: true,
    encapsulation: ViewEncapsulation.Emulated,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class S25CopyVariableComponent {
    @Input({ required: true }) text: string;
    @Input({ required: true }) label: string;

    copyTextToClipBoard(text: string) {
        S25Util.copyToClipboard(text);
    }
}
